<!-- 预约考试 -->
<template>
  <div class="exam-grade-container">
    <div class="exam-condition" v-if="!isCan">
      <div class="text">
        符合以下条件的人员，可以报名参加IP规划师职业资格考试：
        <p>（1）拥护中华人民共和国宪法</p>
        <p>（2）该账户报名参加线上、线下课程学习</p>
        <p>（3）完成线上、线下课程学习，没有缺勤缺课</p>
        <p>（4）完成报名该等级所有录播课程学习</p>
      </div>
      <div class="grade-form">
        <div class="label">考试等级</div>
        <el-select v-model="form.ipType" placeholder="请选择" @change="changeIpType">
          <el-option
            v-for="(item,index) in ipTypeExamList"
            :key="'ipType'+index"
            :label="item.examName"
            :value="item.ipType">
          </el-option>
        </el-select>
        <div class="btns">
          <el-button>疑难解答</el-button>
          <el-button type="primary" @click="checkHasCanExam">预约考试</el-button>
        </div>
      </div>
    </div>
    <div class="exam-content" v-else>
      <el-steps class="enroll-steps"  :class="'active'+active" :active="active" align-center>
        <el-step title="选择考区"></el-step>
        <el-step title="填写信息"></el-step>
        <el-step title="考试缴费"></el-step>
      </el-steps>
      <div class="content" v-if="active==1">
        <div class="list">
          <div class="item" v-for="(item,index) in memberExamList" :key="'exam'+index">
            <div class="item-info">
              <div class="title">{{item.examLocationTitle}}</div>
              <div class="info">
                <div class="adress">
                  <img src="@/assets/icons/icon-adress.png" alt="">
                  {{item.provinceName}}{{item.cityName}}</div>
                <div class="data">
                  <img src="@/assets/icons/icon-data.png" alt="">
                  {{item.examTime | stringData}}
                </div>
              </div>
            </div>
            <el-button type="primary"  class="item-button" :disabled="item.maxNumber <= item.number" @click="next(item.examTimeId)">
              <span v-if="item.maxNumber <= item.number">名额已约满</span>
              <span v-else>预约</span>
            </el-button>
          </div>
        </div>
        <el-pagination 
					background 
					:current-page="page" 
					@current-change="getExamList"
					layout="prev, pager, next" 
					:page-count="totalPage" v-if="memberExamList.length">
					</el-pagination>
        <el-empty description="暂无数据" v-if="!memberExamList.length"></el-empty>
      </div>
      <div class="content" v-else-if="active==2">
        <el-form ref="form" :rules="rules" class="enroll-form" :model="form" label-position="top" label-width="80px">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="姓名：" prop="name">
                <el-input v-model="form.name" placeholder="请输入您的姓名" maxlength="10" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="性别：" prop="sex">
                <el-select v-model="form.sex"  placeholder="请选择">
                  <el-option
                    v-for="item in sexList"
                    :key="'sex'+item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="生日：" prop="birthdayNum">
                <el-date-picker v-model="form.birthdayNum" type="date" format="yyyy-MM-dd" value-format="timestamp"
                placeholder="选择日期" :picker-options="pickerBeginDateBefore"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="邮箱：" prop="email">
                <el-input v-model="form.email"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="手机号：" prop="telephone">
                <el-input v-model="form.telephone"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="身份证号：" prop="idCard">
                <el-input v-model="form.idCard" placeholder="请输入您的身份证号" maxlength="18" show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="考生照片：" prop="photo">
            <div class="card-box">
              <el-upload
                class="card-uploader"
                :action="config.qnuploadurl"
                :show-file-list="false"
                :data="{token: qntoken}"
                :before-upload="beforeUpload"
                :on-success="uploadSuccess"
                accept=".jpg,.jpeg,.png,.gif">
                <img v-if="form.photo" :src="form.photo" class="card-img">
                <div class="upload-img" v-else>
                  <img src="@/assets/icons/icon-photo.png" alt="">
                  <div class="desc">考生本人蓝底一寸照片</div>
                </div>
              </el-upload>
            </div>
          </el-form-item>
          <el-button style="width:180px;margin-top: 20px;" type="primary" @click="next" v-if="active<3">下一步</el-button>
        </el-form>
      </div>
      <div class="content" v-else-if="active==3">
        <el-form class="enroll-form content3-form" label-position="top" label-width="80px">
          <el-form-item label="考试等级：">
            <el-select v-model="form.ipType" disabled  placeholder="请选择">
              <el-option
                v-for="item in ipTypeExamList"
                :key="item.ipType"
                :label="item.examName"
                :value="item.ipType">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="支付方式：">
            <el-radio class="pay" v-model="payType" label="1">
              <img src="@/assets/icons/icon-pay-ali.png" alt="">
              支付宝支付
            </el-radio>
          </el-form-item>
          <el-form-item label="支付金额：">
            <div class="money">￥{{money}}</div>
          </el-form-item>
          <el-button style="width:180px;margin: 20px 0 0 400px;" type="primary" v-preventReClick @click="onSubmit" v-if="active==3">立即支付</el-button>
        </el-form>
      </div>
    </div>
  </div>

</template>

<script>
import config from "@/config";
import moment from "moment";
import { idCardValidity,phoneRules,checkEmail } from "@/utils/rule.js"
export default {
  filters: {
    stringData: function (date) {
      return moment(date * 1000).format("YYYY-MM-DD HH:mm");
    },
  },
  data() {
    return {
      config,
      qntoken:'',
      active:1,
      isCan:0,
      page:1,
      totalPage:0,
      money:0,
      payType:'1',
      form:{
        examTimeId:1,//考试场次时间列表
        ipType:null,// 课程等级  1初级  2中级  3高级  4资深
        name:"",
        sex:1,
        telephone:"",//手机号
        idCard:"",//身份证号
        birthdayNum:'',
        email:"",//邮箱
        photo:""//照片
      },
      pickerBeginDateBefore: {
				disabledDate(time) {
					let timeSpace = time.getTime() > Date.now();
					return timeSpace;
				}
			},
      sexList:[{label:'男',value:1},{label:'女',value:2}],
      ipTypeExamList:[],
      memberExamList:[],
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
        birthdayNum: [
          { required: true, message: '请选择生日', trigger: 'blur' },
        ],
        sex: [
          { required: true, message: '请选择性别', trigger: 'blur' },
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { validator: checkEmail, trigger: ['change','blur'] }
        ],
        telephone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: phoneRules, trigger: ['change','blur'] }
        ],
        photo: [
          { required: true, message: '请上传照片'},
        ],
        idCard: [
          { required: true, message: '请输入身份证号', trigger: 'blur' },
          { validator: idCardValidity, trigger: ['change','blur'] }
        ],
      }
    };
  },
  created(){
    this.getQnToken();
    this.getExamList(this.page);
    this.getCommonList();
  },
  methods: {
    getQnToken() {
			this.$store.dispatch("GetUploadToken").then((res) => {
				this.qntoken = res.data.qnToken;
			});
		},
    getCommonList(){
      this.$http
      .get('/api/web/common/getCommonList')
      .then(res=>{
        this.ipTypeExamList=res.data.ipTypeExamList;
        this.money=this.ipTypeExamList[0].examPrice;
        this.form.ipType=1;
      })
    },
    getExamList(page){
      this.$http
      .get(`/api/web/exam/getExamList?page=${page}&ipType=${this.form.ipType}`)
      .then(res=>{
        this.memberExamList=res.data.memberExamList;
        this.totalPage=res.data.totalPage;
      })
    },
    checkHasCanExam(){
      this.$http
        .get('/api/web/exam/checkHasCanExam?ipType='+this.form.ipType)
        .then(res=>{
          this.isCan=res.data.isCan;
          if(!this.isCan){
            this.$confirm('您申请预约的等级考试还未参加学习，不能申请预约考试。您未报名当前预约等级考试的学习，不允许预约科目考试。', '温馨提示', {
              cancelButtonText: '确定',
              showClose:false,
              showConfirmButton:false,
              customClass:'examMsgBox',
              center: true
            })
          }else{
            this.getExamList(1)
          }
        })
    },
    changeIpType(){
      let index=this.ipTypeExamList.findIndex(item=>item.ipType==this.form.ipType)
      this.money=this.ipTypeExamList[index].examPrice
    },
    uploadSuccess(res){
			this.form.photo=config.qnurl+res.key;
		},
    beforeUpload(file) {
      this.loading = true
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isJPG && !isPNG) {
        this.$message.error('上传图片只能是 JPG 或者 PNG 格式!');
      }
      if (!isLt5M) {
        this.$message.error('上传图片大小不能超过 5MB!');
      }
      return (isJPG || isPNG) && isLt5M;
    },
    onSubmit(){
      this.form.birthday=this.form.birthdayNum/1000;
      this.$http
      .post('/api/web/exam/postAddMemberExam',this.form)
      .then(res=>{
        this.$toast(res.msg)
      })
      .catch(err=>{
        if(err.status==10000){
          this.$toast(err.msg)
        }else{
          document.querySelector('body').innerHTML = err
          document.forms[0].submit()
        }
        
      })     
    },
    next(id) {
      if(this.active==1){
        this.form.examTimeId=id;
        if (++this.active > 3) this.active = 0;
      }else if(this.active==2){
        this.$refs.form.validate((valid) => {
          if (valid) {
            if(this.active++ >2 )this.active = 0;
          } else {
            this.$toast('请填写完整表单')
            return false;
          }
        });
      }
    },
    goBack () {
      if(this.active>1){
        // console.log("点击了浏览器的返回按钮");
        this.$refs.form.clearValidate()
        this.active--
        if(this.active=2){this.isCan=0}
      }else{
        sessionStorage.clear();
        window.history.back();
        history.pushState(null, null, document.URL);
      }
      
    }, 
  },
  mounted () {
    if (window.history && window.history.pushState) {
      // 向历史记录中插入了当前页
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.goBack, false);
    }
  },
  destroyed () {
    window.removeEventListener('popstate', this.goBack, false);
  },
};
</script>

<style lang='less' scoped>
.exam-grade-container{
  margin-top: 0px;
  background: #fff;
  .exam-condition{
    padding: 50px 295px 0;
    min-height: 663px;
    margin: 0 auto;   
    .text{
      text-align: left;
    }
    .grade-form{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 40px;
      .label{
        font-size: 16px;
        font-weight: 700;
        color: #333;
        text-align: left;
      }
      /deep/.el-select{
        width: 440px;
        margin-top:20px;
        margin-bottom: 50px;
        .el-input{
          .el-input__inner{
            height: 50px;
          }
        }
      }
      .btns{
        width: 100%;
        .el-button{
          height: 40px;
          padding: 0 37px;
          font-size: 16px;
          color: #D71A18;
          border: 1px solid #D71A18;
          &.el-button--primary{
            color: #fff;
          }
        }
      }
    }
  }
  /deep/.enroll-steps{
    padding: 35px 30px 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid #f5f5f5;
    .el-step{
      margin-left: -20px;
    }
    .el-step__icon{
      width: 30px;
      height: 30px;
    }
    .el-step__line{
      top: 13px;
    }
    .el-step__icon-inner{
      font-size: 18px;
    }
    .el-step__title{
      position: absolute;
      top: -3px;
      left: calc(50% + 10px);
      padding: 0 10px;
      background: #fff;
    }
    .el-step__title.is-process {
      font-weight: 500;
      color: #999;
    }
    .el-step__head.is-finish {
      color: #d71a18;
      border-color: #e7e7e7;
    }
    &.active3{
      .el-step{
        .is-finish {
          color: #d71a18;
          border-color: #d71a18;
        }
      }
    }
    &.active2{
      .el-step:first-child{
        .is-finish {
          color: #d71a18;
          border-color: #d71a18;
        }
      }
      .is-text{
        border-color: #d71a18;
      }
      .el-step__head.is-finish {
        .el-step__icon{
          color: #fff;
          background: #d71a18;    
        }
        .el-step__line-inner{
          border-width:0 !important;
        }
        .el-step__line{
          border-style:dashed;
          border-width: 1px;
          // border: 1px dashed #e7e7e7;
          background: none;
        }
      }
    }
    .el-step__head.is-finish {
      .el-step__icon{
        color: #fff;
        background: #d71a18;    
      }
      .el-step__line-inner{
        border-width:0;
      }
      .el-step__line{
        border-style:dashed;
        border-width: 1px;
        background: none;
      }
      .el-step__line-inner{
        border-width: 0 !important;
      }
    }
    .el-step__head.is-process {
      .el-step__icon{
        color: #fff;
        font-weight: 700;
        background: #e7e7e7;
        border-color:#e7e7e7 ;    
      }
      .el-step__line{
        border: 1px dashed #e7e7e7;
        background: none;
      }
    }
    .el-step__head.is-wait {
      .el-step__icon{
        color: #fff;
        font-weight: 700;
        background: #e7e7e7;
        border-color:#e7e7e7 ;    
      }
      .el-step__line{
        border: 1px dashed #e7e7e7;
        background: none;
      }
    }
  }
   /deep/.enroll-form{
    padding:30px 0;
    .el-form-item{
      margin-bottom: 30px;
    }
    .el-select,.el-cascader{
      width: 100%;
    }
    .el-form-item__label{
      width: 100%;
      line-height: 1;
      color: #333;
      font-size: 16px;
      height: 36px;
      padding-bottom: 20px;
    }
    .el-input__inner{
      height: 50px;
      font-size: 16px;
    }
    .el-date-editor{
      width: 100%;
    }
    .card-box{
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 5px;
      .card-uploader {
        margin-right: 35px;
      }
      .card-uploader .el-upload{
        position: relative;
        width: 240px;
        height: 140px;
        border: 1px solid #EAEAEA;
        border-radius: 5px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        .desc{
          line-height: 1;
          height: 20px;
          font-size: 15px;
          color: #ccc;
        }
      }
      .card-img{
        width: 250px;
        height: 190px;
      }
      .tips{
        margin-left: auto;
        margin-right: 30px;
        color: #ccc;
        font-size: 14px;
      }
    }
    .file-upload{
      .el-input-group__append{
        position: relative;
      }
      .file-uploader{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 50px;
        .el-upload{
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .exam-content{
    padding-bottom: 50px;
    .content{
      padding: 0 50px 40px 40px;
      .list{
        width: 730px;
        margin: 0 auto;
        .item{
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 20px 30px;
          margin-bottom: 32px;
          border-radius: 15px;
          background: #f5f5f5;
          .item-info{
            text-align: left;
            .title{
              margin-bottom: 20px;
              font-size: 16px;
              font-weight: 700;
              color: #333;
            }
            .info{
              display: flex;
              align-items: center;
              font-size: 14px;
              color: #666;
              img{margin-right: 8px;}
              .adress{
                display: flex;
                align-items: center;
                margin-right: 25px;
              }
              .data{
                display: flex;
                align-items: center;
              }
            }
          }
          .item-button{
            min-width: 100px;
            height: 30px;
            padding: 0 20px;
            align-items: center;
          }
        }
      }
    }
    .content3-form{
      width: 590px;
      .money{
        font-size: 30px;
        font-weight: 700;
        text-align: left;
        color: #D71A18;
      }
      /deep/.el-input.is-disabled .el-input__inner {
        background-color: #fff;
        color: #666;
        cursor: not-allowed;
      }
      /deep/.pay{
        width: 280px;
        height: 90px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border: 1px solid #D71A18;
        border-radius: 4px;
        .el-radio__inner{
          width: 18px;
          height: 18px;
          &::after{
            width: 10px;
            height: 10px;
          }
        }
        .el-radio__label{
          display: flex;
          align-items: center;
          color: #000;
          font-size: 14px;
          padding-left: 20px;
          img{
            margin-right: 10px;
          }
        }
      }
    }
  }
}
</style>
